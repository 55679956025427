<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-rented-machines' }">{{
            $t("MENU.ITEM.ASSETS.RENTED_MACHINES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingMachine"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { SAVE_RENTED_MACHINE } from "@/modules/rented-machines/store/rentedmachine.module";
import { FETCH_MACHINES_TYPES } from "@/modules/machines-types/store/machinetype.module";
import { FETCH_SUBCONTRACTORS } from "@/modules/subcontractors/store/subcontractor.module";
import { FETCH_FUELS_TYPES } from "@/modules/fuels-types/store/fuel-type.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      msmHours: 0,
      msmPrice: 0,
      own_machine: 0,
      errors: {},
      machines_types: [],
      fuel_type: [],
      subcontractors: []
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    FormSelect,
    FormInput
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ASSETS.RENTED_MACHINES"),
        route: { name: "list-rented-machines" }
      },
      { title: this.$i18n.t("MENU.ITEM.ADD") }
    ]);

    this.$store
      .dispatch(
        FETCH_SUBCONTRACTORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name",
          subcontractorTypeId: 1
        })
      )
      .then(data => {
        this.subcontractors = data.data.items;
      });

    this.$store.dispatch(FETCH_FUELS_TYPES).then(data => {
      this.fuel_type = data.data.items;
    });
    this.$store
      .dispatch(
        FETCH_MACHINES_TYPES,
        this.$url.transformParams({ onlySelectValues: true })
      )
      .then(data => {
        this.machines_types = data.data.items;
        vm.generateFormOutOfSchemaJson(this.schemaJson);
      });
    vm.generateFormOutOfSchemaJson(this.schemaJson);
  },
  computed: {
    ...mapGetters(["isLoadingRentedMachine", "isLoadingSharedStore"]),
    ...mapState({
      errors: state => state.auth.errors
    }),

    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.REGISTRATION_PLATE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_REGISTRATION_PLATE",
              validation: "FORM.VALID_NAME"
            },
            validations: {},
            translatable: false,
            model: "registrationPlate"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            colSm: "3",
            feedback_id: "unit-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_TYPE"
            },
            options: this.machines_types,
            validations: {
              required: true
            },
            model: "machineTypeId"
          },
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            divider: false,
            i18n: {
              label: "FORM_LABELS.TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_TYPE",
              validation: "FORM.VALID_TYPE"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "machineName"
          },
          {
            type: "input",
            inputType: "text",
            id: "machine-model-name-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_MODEL",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_MODEL",
              validation: "FORM.VALID_MACHINE_MODEL"
            },
            validations: {},
            translatable: false,
            model: "machineModel"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            colSm: "6",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_FUEL_TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_FUEL_TYPE"
            },
            options: this.fuel_type,
            validations: {
              required: true
            },
            model: "fuelTypeId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-subcontractor-group",
            id: "item-subcontractor-input",
            feedback_id: "unit-live-feedback",
            colSm: "6",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.SUBCONTRACTOR",
              placeholder: "FORM_LABELS.SUBCONTRACTOR"
            },
            options: this.subcontractors,
            validations: {
              required: true
            },
            model: "subcontractorId"
          },
          {
            type: "input",
            inputType: "text",
            id: "msm-price-input",
            groupId: "provider-name-group",
            colSm: "6",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "MENU.ITEM.STAFF.STAFF_NAMES",
              placeholder: "MENU.ITEM.STAFF.STAFF_NAMES"
            },
            validations: {},
            translatable: false,
            model: "staffName"
          },
          {
            type: "input",
            inputType: "text",
            id: "msm-phone-input",
            groupId: "staff-phone-name-group",
            colSm: "6",
            feedback_id: "staff-phone-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.PHONE",
              placeholder: "FORM_PLACEHOLDERS.PHONE",
              validation: "FORM.VALID_PHONE"
            },
            validations: {
              minLength: 10,
              maxLength: 10
            },
            translatable: false,
            model: "staffPhone"
          },
          {
            type: "switch",
            groupId: "staff-no-mileage-group",
            id: "staff-no-mileage-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "no-mileage-live-feedback",
            colSm: "auto",
            divider: true,
            default: false,
            i18n: {
              label: "FORM_LABELS.NO_MILEAGE",
              placeholder: "FORM_PLACEHOLDERS.NO_MILEAGE",
              validation: "FORM.VALID_NO_MILEAGE"
            },
            validations: {
              required: true
            },
            model: "noMileage"
          },
          {
            type: "switch",
            groupId: "staff-is-assignable-group",
            id: "staff-is-assignable-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "is-assignable-live-feedback",
            colSm: "auto",
            divider: true,
            default: true,
            i18n: {
              label: "FORM_LABELS.IS_ASSIGNABLE",
              placeholder: "FORM_PLACEHOLDERS.IS_ASSIGNABLE",
              validation: "FORM.VALID_IS_ASSIGNABLE"
            },
            validations: {
              required: true
            },
            model: "isAssignable"
          }
        ]
      };
    }
  },
  validations() {
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_RENTED_MACHINE, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-rented-machine",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-rented-machine" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-rented-machines" });
          }
        })
        .catch(response => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
